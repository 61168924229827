// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function capitalize(composite = false) {
	if (!composite) return this.charAt(0).toUpperCase() + this.slice(1);
	let s = this.split('-');
	if (s.length > 1) {
		let w = '';
		s.map((name) => {
			w += `-${name.charAt(0).toUpperCase() + name.slice(1)}`;
			return name;
		});
		return w.slice(1);
	}
	s = this.split(' ');
	if (s.length > 1) {
		let w = '';
		s.map((name) => {
			w += ` ${name.charAt(0).toUpperCase() + name.slice(1)}`;
			return name;
		});
		return w.slice(1);
	}
	return this.charAt(0).toUpperCase() + this.slice(1);
};
// eslint-disable-next-line no-extend-native
String.prototype.capitalizeFirstLetter = function capitalizeFirstLetter() {
	return this.charAt(0).toUpperCase() + this.slice(1);
};
