const {
	REACT_APP_AWS_COGNITO_CLIENT_ID,
	REACT_APP_AWS_COGNITO_REGION,
	REACT_APP_AWS_COGNITO_USER_POOL_ID,
	REACT_APP_API_URL,
	REACT_APP_STAGE,
} = process.env;

export default {
	Auth: {
		// REQUIRED - Amazon Cognito Region
		region: REACT_APP_AWS_COGNITO_REGION,
		// OPTIONAL - Amazon Cognito Federated Identity Pool Region
		// Required only if it's different from Amazon Cognito Region
		// identityPoolRegion: 'XX-XXXX-X',
		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: REACT_APP_AWS_COGNITO_USER_POOL_ID,
		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: REACT_APP_AWS_COGNITO_CLIENT_ID,
		// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
		mandatorySignIn: false,
	},
	API: {
		endpoints: [{
			name: REACT_APP_STAGE,
			endpoint: REACT_APP_API_URL,
		},
		],
	},
};
