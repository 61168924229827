export const getFullName = ({ firstName, lastName }) => `${firstName.capitalize()} ${lastName.toUpperCase()}`;
export const getFullNameInversed = ({ firstName, lastName }) => `${lastName.toUpperCase()} ${firstName.capitalize()}`;

export const scrollToTop = (behavior = 'smooth') => {
	window.scrollTo({
		top: 0,
		left: 0,
		behavior,
	});
};
