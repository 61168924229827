import { userGroup as groups } from 'api/core/lib/user-group';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import {
	Route,
	Redirect,
	useHistory,
} from 'react-router-dom';
import { useEventCallback } from '../lib/hooks/useEventCallback';

import { UserContext } from './UserContext';

export const PrivateRoute = (props) => {
	const history = useHistory();
	const context = useContext(UserContext);
	const { updateCurrentUser } = context;
	const onListen = useEventCallback(updateCurrentUser);

	useEffect(() => {
		const unlisten = history.listen(() => {
			onListen();
		});
		return () => {
			unlisten();
		};
	}, [history, onListen]);

	const { isLoaded } = context;
	if (!isLoaded) return null;

	const {
		component: Comp, group, notGroup, ...rest
	} = props;
	// eslint-disable-next-line react/destructuring-assignment
	const userGroups = context.user?.signInUserSession?.accessToken?.payload['cognito:groups'];
	if (!userGroups) throw new Error('User has no groups');
	// eslint-disable-next-line react/destructuring-assignment
	const isAuthenticated = !!(context.user?.username);
	let hasPermission = false;
	if (typeof group === 'string') {
		hasPermission = userGroups?.includes(group);
		if (hasPermission && notGroup) {
			hasPermission = !userGroups?.includes(notGroup);
		}
	} else {
		hasPermission = group?.some((g) => userGroups?.includes(g));
		if (hasPermission && notGroup) {
			hasPermission = !notGroup?.some((g) => userGroups?.includes(g));
		}
	}
	const isAllowed = hasPermission && isAuthenticated;
	return (
		<Route
			{...rest}
			render={(_props) => {
				if (isAllowed) {
					return 	(<Comp {..._props} />);
				}
				if (isAuthenticated && !isAllowed && isLoaded) {
					if (userGroups.includes(groups.Admin)) {
						return (<Redirect from="/" to="/admin" />);
					}
					if (userGroups.includes(groups.AnimatorVision)) {
						return (<Redirect from="/" to="/missions" />);
					}
					if (userGroups.includes(groups.DC)) {
						return (<Redirect from="/" to="/DC" />);
					}
					if (userGroups.includes(groups.DA)) {
						return (<Redirect from="/" to="/DA" />);
					}
					if (userGroups.includes(groups.DG)) {
						if (userGroups.includes(groups.GP)) {
							return (<Redirect from="/" to="/DG/interventions" />);
						}
						return (<Redirect from="/" to="/DG" />);
					}
					return (<Redirect to={{ pathname: '/forbidden' }} />);
				}
				return (<Redirect to={{ pathname: '/login' }} />);
			}}
		/>
	);
};

PrivateRoute.propTypes = {
	component: PropTypes.oneOfType([
		Route.propTypes.component,
		PropTypes.object,
	]).isRequired,
	group: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.arrayOf(PropTypes.string),
	]).isRequired,
	notGroup: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.arrayOf(PropTypes.string),
	]),
};
PrivateRoute.defaultProps = {
	notGroup: null,
};
