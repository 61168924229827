const userGroup = {
	Admin: 'Admin',
	Animator: 'Animator',
	SuperAdmin: 'SuperAdmin',
	DG: 'DG',
	DC: 'DC',
	DA: 'DA',
	BEL: 'BEL', //CECAZ
	GP: 'GP', //CECAZ
	RMS: 'RMS', //CEPAL
	AnimatorVision: 'AnimatorVision', //CEPAL
};

module.exports = {
	userGroup,
};
