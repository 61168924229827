import { useCallback, useLayoutEffect, useRef } from 'react';

export const useEventCallback = (fn) => {
	const ref = useRef(fn);
	useLayoutEffect(() => {
		ref.current = fn;
	});
	return useCallback((...args) => {
		const { current } = ref;
		return current(...args);
	}, []);
};
