import React from 'react';
import PropTypes from 'prop-types';
import {
	Container, Col, Row, CardHeader, Card, CardBody, CardFooter, Button,
} from 'reactstrap';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		if (error?.name === 'ChunkLoadError') {
			global.rollbar.warn('#chunk ChunkLoadError');
			return { hasError: true, chunkload: true };
		}
		// if (process.env.NODE_ENV === 'development') {
		// 	return { hasError: false };
		// }
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		global.rollbar.error(`#chunk Rollbar Error caught by ErrorBoundary: ${error} ${errorInfo}`);
	}

	render() {
		const { hasError, chunkload } = this.state;
		const { children } = this.props;
		if (hasError) {
			return (
				<Container style={{ height: '100vh' }} className="d-flex align-items-center justify-content-center">
					<Row>
						<Col>
							<Card>
								{chunkload ? (
									<CardHeader>L&apos;application doit être mise à jour</CardHeader>
								) : (
									<CardHeader>Une erreur est survenue !</CardHeader>
								)}
								<CardBody>
									{!chunkload && (
										<>
											L&apos;équipe de développement vient d&apos;être informée.
											<br />
										</>
									)}
									Cliquez sur le bouton ci-dessous pour recharger l&apos;application.
								</CardBody>
								<CardFooter className="d-flex align-items-center justify-content-end">
									<Button onClick={() => {
										this.setState({ hasError: false });
										// history.push('/');
										window.location.href = '/';
									}}
									>
										Retour
									</Button>
								</CardFooter>
							</Card>
						</Col>
					</Row>
				</Container>
			);
		}
		return children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.node.isRequired,
};

const MainErrorBoundary = ErrorBoundary;
export { MainErrorBoundary as ErrorBoundary };
