import { HashRouter, Switch } from 'react-router-dom';
import {
	useEffect, useState, useMemo,
} from 'react';
import { Auth, Hub } from 'aws-amplify';
import { setDefaultOptions } from 'date-fns';
import { registerLocale } from 'react-datepicker';

import { fr } from 'date-fns/locale';
import {
	QueryClient,
	QueryClientProvider,
} from 'react-query';
import { userGroup } from 'api/core/lib/user-group';

import { UserContext } from './components/UserContext';
import { ErrorBoundary } from './components/ErrorBoundary';
import { ScrollToTop } from './components/Scroll/ToTop';
import { Routes } from './Routes';

import 'react-datepicker/dist/react-datepicker.css';

import './App.scss';

registerLocale('fr', fr);
setDefaultOptions({ locale: fr });

const queryClient = new QueryClient();

/*const DeveloperPageAuthResetPassword = props => <PageAuthResetPassword developer {...props} />;
const DeveloperPageAuthResetPasswordWithCode = props => (
	<DeveloperPageAuthResetPassword withCode {...props} />);
*/

const App = () => {
	const [isLoaded, setIsLoaded] = useState(false);
	const [currentUser, setCurrentUser] = useState(null);

	const updateCurrentUser = async (user, force = false) => {
		if (user) {
			const userGroups = user?.signInUserSession?.accessToken?.payload['cognito:groups'] || [];
			setCurrentUser({
				...user,
				sub: user.attributes.sub,
				isAdmin: userGroups.includes(userGroup.Admin),
				isAnimator: userGroups.includes(userGroup.Animator),
				isSuperAdmin: userGroups.includes(userGroup.SuperAdmin),
				isDA: userGroups.includes(userGroup.DA),
				isDC: userGroups.includes(userGroup.DC),
				isDG: userGroups.includes(userGroup.DG),
				isBEL: userGroups.includes(userGroup.BEL),
				isGP: userGroups.includes(userGroup.GP),
				isRMS: userGroups.includes(userGroup.RMS),
				isAnimatorVision: userGroups.includes(userGroup.AnimatorVision),
			});

			return;
		}
		try {
			let authUser;
			if (force) {
				authUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
			} else {
				authUser = await Auth.currentAuthenticatedUser()
					.catch((e) => {
						// eslint-disable-next-line no-console
						console.log(e);
						return false;
					});
			}
			const userGroups = authUser?.signInUserSession?.accessToken?.payload['cognito:groups'] || [];
			if (authUser === false) {
				setCurrentUser(false);
			} else {
				setCurrentUser({
					...authUser,
					sub: authUser.attributes.sub,
					isAdmin: userGroups.includes(userGroup.Admin),
					isAnimator: userGroups.includes(userGroup.Animator),
					isSuperAdmin: userGroups.includes(userGroup.SuperAdmin),
					isDA: userGroups.includes(userGroup.DA),
					isDC: userGroups.includes(userGroup.DC),
					isDG: userGroups.includes(userGroup.DG),
					isBEL: userGroups.includes(userGroup.BEL),
					isGP: userGroups.includes(userGroup.GP),
					isRMS: userGroups.includes(userGroup.RMS),
					isAnimatorVision: userGroups.includes(userGroup.AnimatorVision),
				});
			}
			setIsLoaded(true);
		} catch (err) {
			setCurrentUser(null);
			setIsLoaded(true);
		}
	};

	useEffect(() => {
		updateCurrentUser();
		Hub.listen('auth', (data) => {
			if (data.payload.event !== 'signIn') {
				updateCurrentUser();
			}
		});
	}, []);

	const providerUserValue = useMemo(() => ({
		user: currentUser,
		updateCurrentUser,
		isLoaded,
	}), [currentUser, isLoaded]);

	return (
		<ErrorBoundary>
			<QueryClientProvider client={queryClient}>
				<UserContext.Provider value={providerUserValue}>
					<HashRouter>
						<ScrollToTop>
							<Switch>
								<Routes />
							</Switch>
						</ScrollToTop>
					</HashRouter>
				</UserContext.Provider>
			</QueryClientProvider>
		</ErrorBoundary>
	);
};

export default App;
