import { setGlobalDateI18n, setGlobalDateMasks } from 'fecha';

setGlobalDateMasks({
	default: 'DD/MM/YYYY HH:mm',
	editDate: 'DD/MM/YY',
	editDateTime: 'DD/MM/YY HH:mm',
	editTime: 'HH:mm',
	full: 'dddd DD MMMM YYYY à HH:mm',
	myMask: 'HH:mm:ss YY/MM/DD',
	short: 'DD/MM/YYYY',
	shortDate: 'DD/MM/YYYY',
});

// Override fecha.i18n to support any language
setGlobalDateI18n({
	dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
	dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
	monthNamesShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jui', 'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
	monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
	amPm: ['am', 'pm'],
	// D is the day of the month, function returns something like...  3rd or 11th
	// eslint-disable-next-line
	DoFn: (D) => {
		// eslint-disable-next-line
		return D + ['er', 'ème', 'ème', 'ème'][D % 10 > 3 ? 0 : (D - D % 10 !== 10) * D % 10 ];
	},
});
