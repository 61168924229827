import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scrollToTop } from '../../lib/utils';

export const ScrollToTop = ({ children }) => {
	const { pathname } = useLocation();
	useEffect(() => {
		scrollToTop();
	}, [pathname]);

	return children || null;
};

ScrollToTop.propTypes = {
	children: PropTypes.node.isRequired,
};
